import { isAuth } from '@/setting'
import type { App } from 'vue'
import { useUser } from '../stores/user'
export default (app: App) => {
  const hooks = {
    mounted (el: HTMLElement, binding: any) {
      if (isAuth) {
        const { value } = binding
        const valueType = Object.prototype.toString.call(value).slice(8, -1)
        if (!value) {
          return
        }
        if (!['String', 'Array'].includes(valueType)) {
          throw new Error('Missing String value or Array value when using v-auth')
        }
        const userInfo = useUser()
        // 全部权限
        if (userInfo.permissions.includes('*:*:*' as never)) {
          return
        }
        // 如果传入字符串，且有权限，不做操作，直接退出
        if (valueType === 'String' && userInfo.permissions.some((item: string) => item === value)) {
          return
        }
        // 如果传入数组，且有权限，不做操作，直接退出
        if (
          valueType === 'Array' &&
          value.every((v: string) => userInfo.permissions.some((item: string) => item === v))
        ) {
          return
        }
        // 无权限，移除当前元素
        el.parentNode && el.parentNode.removeChild(el)
      }
    },
  }

  app.directive('auth', hooks)
}
