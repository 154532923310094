import pinia from './stores/index'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import directives from '@/directives'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './styles/index.scss'
import * as echarts from 'echarts'

const app = createApp(App)
app.config.performance = true
window.echarts = echarts

// 注册全局指令
directives(app)

app.use(pinia).use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
