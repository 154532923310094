import { reqLogin, reqUserPermission } from '@/api/user'
import router from '@/router'
import { tokenFiledName } from '@/setting'
import { removeAllStorage, setStorage } from '@/utils/storage'
import { defineStore } from 'pinia'

export const useUser = defineStore('user', {
  state: () => ({
    user: {},
    roleList: [],
    menuList: [],
    dept: {},
    authorityList: [],
    postList: [],
    topDept: {},
    ldDept: {},
    areaList: [],
    postList: [],
    permissionStatus: false,
  }),
  persist: true,
  actions: {
    /**
     * 登录
     * @param payload
     * @returns
     */
    login(payload: object) {
      return new Promise((resolve, reject) => {
        // 发送请求
        reqLogin<{ token: string; refreshToken: string }>(payload)
          .then(data => {
            const { token: accessToken, refreshToken } = data
            // 存储 token
            accessToken && setStorage(tokenFiledName, accessToken)
            refreshToken && setStorage('refreshToken', refreshToken)
            resolve(data)
          })
          .catch(err => {
            console.log('登录接口出错')
            reject(err)
          })
      })
    },

    /**
     * 获取用户与权限信息
     * @returns
     */
    asyncGetUserInfo() {
      return new Promise((resolve, reject) => {
        reqUserPermission()
          .then((res: any) => {
            this.user = res.user || {}
            this.roleList = res.roleList || []
            this.menuList = res.menuList || []
            this.dept = res.dept || {}
            this.topDept = res.topDept || {}
            this.ldDept = res.ldDept || {}
            this.areaList = res.areaList || []
            this.postList = res.postList || []
            this.authorityList = res.authorityList || []
            this.postList = res.postList || []
            this.permissionStatus = true
            resolve(res)
          })
          .catch(reject)
      })
    },

    /**
     * 用户退出
     */
    async logOut() {
      // await reqLoginOut()
      removeAllStorage()
      this.user = {}
      this.roleList = []
      this.menuList = []
      this.dept = {}
      this.topDept = {}
      this.ldDept = {}
      this.ldDept = []
      this.postList=[]
      this.authorityList = []
      this.postList = []
      this.permissionStatus = false
      router.replace('/login')
    },
  },
})
