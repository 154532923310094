import { ElNotification } from 'element-plus'

const { VITE_APP_ASSETS_BASE_URL } = import.meta.env

/**
 * 生成mock数据
 * @param { Number } total 生成条数
 * @param { Function } generateFuc 生成方法
 * @returns
 */
export function generator(total = 10, generateFuc: () => object): Array<object> {
  const res = []
  for (let index = 0; index < total; index++) {
    res.push(generateFuc())
  }
  return res
}

/**
 * 获取数据类型
 *
 * @format
 * @param value
 * @returns
 */

export const getType = (value: any) => Object.prototype.toString.call(value).slice(8, -1)

/**
 * 生成区间随机数
 * @param { Number } start
 * @param { Number } end
 * @returns
 */
export const getRandomNumber = (start: number, end: number) =>
  Number.parseInt(`${Math.random() * (end - start) + start}`)

/**
 * 随机生成指定长度字符串
 * @param length
 * @returns
 */
export const getUniqueKey = (length = 10) => {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const end = characters.length
  return Array.from({ length }, () => characters[getRandomNumber(0, end)]).join('')
}

/**
 * blob流文件 下载
 * @param { Blob } blob 文件流
 * @param { String } filename 文件名称
 */
export const blobDownload = (blob: Blob, filename: string) => {
  if ('download' in document.createElement('a')) {
    const elink = document.createElement('a')
    elink.download = filename
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
  } else {
    navigator.msSaveBlob(blob, filename)
  }
}

/**
 * 通过url下载网络文件
 * @param { String } url 文件url
 * @param { String } filename 文件名称
 * @return { Promise }
 */
export const download = (url: string, filename = Date().valueOf()) =>
  new Promise((resolve, reject) => {
    if (!url) {
      // 展示错误消息
      ElNotification({
        message: '文件路径为空',
        type: 'error',
        duration: 3 * 1000,
        showClose: true,
      })
      reject(new Error('文件路径为空'))
      return
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      responseType: 'blob',
    })
      .then((response: any) => {
        if (response.status !== 200) {
          ElNotification({
            message: `文件下载失败 code:${response.status}`,
            type: 'error',
            duration: 3 * 1000,
            showClose: true,
          })
          reject(Error(`${response.status} ${response.type}`))
          return
        }
        const fileName =
          response.headers['download-filename'] ?? response.headers['content-disposition']?.split('fileName=')[1]
        const suffix = url?.slice(url.lastIndexOf('.'))
        response.blob().then((blob: any) => {
          blobDownload(blob, `${filename || fileName}${fileName ? '' : suffix}`)
          resolve(blob)
        })
      })
      .catch(err => {
        // 展示错误消息
        ElNotification({
          message: '文件下载失败',
          type: 'error',
          duration: 3 * 1000,
          showClose: true,
        })
        reject(err)
      })
  })

/**
 * 打开本地文件
 * @param { String } accept 文件格式
 * @param { Boolean } false 是否多选
 * @return { Promise } files 文件列表
 */
export const openLocalFolder = (accept = '*', multiple = false): Promise<FileList> =>
  new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = accept
    input.multiple = multiple
    try {
      input.oninput = () => {
        resolve(input.files as FileList)
      }
    } catch (error) {
      reject(error)
    }
    input.click()
  })

/**
 * 变量类型判断
 * @param { String } type - 需要判断的类型
 * @param { any } value - 需要判断的值
 * @returns { Boolean } - 是否该类型
 */
export const isType = (type: any, value: any) => Object.prototype.toString.call(value).slice(8, -1) === type

/**
 * 深拷贝变量-递归算法(recursive algorithm)
 * 支持 String,Number,Boolean,null,undefined,Object,Array,Date,RegExp,Error 类型
 * @param { any } arg - 需要深拷贝的变量
 * @returns { any } - 拷贝完成的值
 */
export const deepCopyRA = (arg: any) => {
  const newValue = isType('Object', arg) // 判断是否是对象
    ? {}
    : isType('Array', arg) // 判断是否是数组
    ? []
    : isType('Date', arg) // 判断是否是日期对象
    ? new arg.constructor(+arg)
    : isType('RegExp', arg) || isType('Error', arg) // 判断是否是正则对象或错误对象
    ? new arg.constructor(arg)
    : arg
  // 判断是否是数组或对象
  if (isType('Object', arg) || isType('Array', arg)) {
    // 循环遍历
    // eslint-disable-next-line
    for (const key in arg) {
      // 防止原型链的值
      Object.prototype.hasOwnProperty.call(arg, key) && (newValue[key] = deepCopyRA(arg[key]))
    }
  }
  return newValue
}

/**
 * 返回object keys数组
 * @param obj
 * @returns
 */
export function objectKeys<T extends Record<string, any>>(obj: T): Array<keyof T> {
  return Array.from(Object.keys(obj)) as any
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export const constructTreeStructure = <T, K extends keyof T>(
  data: T,
  id = 'id',
  parentId = 'parentId',
  children = 'children'
) => {
  const config: {
    id: string
    parentId: string
    childrenList: string
  } = {
    id,
    parentId,
    childrenList: children,
  }

  const childrenListMap: any = {}
  const nodeIds: any = {}
  const tree: T | [] = []

  const adaptToChildrenList = (o: any) => {
    if (childrenListMap[o[config.id as K]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      for (const c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }
  for (const d of data) {
    const parentId = d[config.parentId as K]
    if (childrenListMap[parentId] === undefined) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id as K]] = d
    childrenListMap[parentId].push(d)
  }

  for (const d of data) {
    const parentId = d[config.parentId as K]
    if (nodeIds[parentId] === undefined) {
      tree.push(d)
    }
  }

  for (const t of tree) {
    adaptToChildrenList(t)
  }

  return tree
}

/**
 * 补全资源前缀
 * @param {string} url
 * @returns
 */
export const toFullUrl = (url: string) => {
  if (String(url).indexOf('http') !== 0) {
    return `${VITE_APP_ASSETS_BASE_URL}${url}`
  }
  return url
}
