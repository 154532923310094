// 全局指令
import type { App } from 'vue'
import auth from './auth'

const install = (app: App) => {
  // 用户权限指令
  auth(app)
}

export default install
