import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { routerAfterEach, routerBeforeEach } from './routerGuard'

// 无权限的开放路由
const publicRoute: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '登录' },
  },
]

export const notFoundAndNoPower = [
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/layout/err/404.vue'),
    meta: {
      title: 'message.staticRoutes.notFound',
      isHide: true,
    },
  },
]

/*
 * 后台基础静态路由
 */
export const layoutRoute: RouteRecordRaw = {
  path: '/',
  name: '/',
  redirect: 'home',
  component: () => import('@/layout/index.vue'),
  children: [
    {
      path: 'home',
      component: () => import('@/views/home/index.vue'),
      meta: { title: '工作台', icon: '' },
    },
    {
      path: 'myToDoList',
      component: () => import('@/views/myToDoList/index.vue'),
      meta: { title: '我的待办', icon: '' },
    },
    {
      path: 'systemMessage',
      component: () => import('@/views/systemMessage/index.vue'),
      meta: { title: '系统消息', icon: '' },
    },
    {
      path: 'moduleManage',
      component: () => import('@/views/moduleManage/index.vue'),
      meta: { title: '管理工作台', icon: '' },
    },
    {
      path: 'personalCenter',
      component: () => import('@/views/userInfo/index.vue'),
      meta: { title: '个人中心', icon: '' },
    }
  ],
}
const router = createRouter({
  history: createWebHistory(),
  routes: [...publicRoute, layoutRoute, ...notFoundAndNoPower],
})
router.beforeEach(routerBeforeEach)
router.afterEach(routerAfterEach)
export default router
