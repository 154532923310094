/**
 * @type { string }
 * @description 全局的令牌字段名
 */
export const tokenFiledName = 'token'

/**
 * @type { string }
 * @description 接口需要的的令牌字段名
 */
export const apiTokenFiledName = 'Authorization'

/**
 * @type { boolean }
 * @description 是否开启鉴权
 */
export const isAuth = true

/**
 * @type { boolean }
 * @description 是否开启部门
 */
export const hasDepartment = false
