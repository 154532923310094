import pinia from '@/stores'
import { toRef } from 'vue'
import { useConfig } from '@/stores/config'
const config = useConfig(pinia)
const isKeepLoginStatus = toRef(config, 'isKeepLoginStatus')
/**
 * sessionStorage 设置数据
 * @param key
 * @param value
 * @returns
 */
export const setSession = (key: string, value: unknown) => sessionStorage.setItem(key, JSON.stringify(value))

/**
 * localStorage 设置数据
 * @param key
 * @param value
 * @returns
 */
export const setLocal = (key: string, value: unknown) => localStorage.setItem(key, JSON.stringify(value))

/**
 * sessionStorage 获取数据
 * @param key
 * @returns
 */
export const getSession = (key: string) => JSON.parse(sessionStorage.getItem(key) as string)

/**
 * localStorage 获取数据
 * @param key
 * @returns
 */
export const getLocal = (key: string) => JSON.parse(localStorage.getItem(key) as string)

/**
 * sessionStorage 删除数据
 * @param key
 * @returns
 */
export const removeSession = (key: string) => sessionStorage.removeItem(key)

/**
 * localStorage 删除数据
 * @param key
 * @returns
 */
export const removeLocal = (key: string) => localStorage.removeItem(key)

/**
 * sessionStorage 删除所有数据
 * @returns
 */
export const removeAllSession = () => sessionStorage.clear()

/**
 * localStorage删除所有数据
 * @returns
 */
export const removeAllLocal = () => localStorage.clear()

// 根据配置自动存取用户令牌
export const setStorage = (key: string, value: unknown) =>
  isKeepLoginStatus.value ? setLocal(key, value) : setSession(key, value)
export const getStorage = (key: string) => (isKeepLoginStatus.value ? getLocal(key) : getSession(key))
export const removeStorage = (key: string) => (isKeepLoginStatus.value ? removeLocal(key) : removeSession(key))
export const removeAllStorage = () => (isKeepLoginStatus.value ? removeAllLocal() : removeAllSession())
