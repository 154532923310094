import { tokenFiledName } from '@/setting'
import { getStorage, setStorage } from './storage'
/**
 * 是否已登录
 * @returns Boolean
 */
export const isLogin = (): boolean => {
  const token: string = getStorage(tokenFiledName)
  return Boolean(token)
}

/**
 * 保存token
 * @param token
 */
export const saveToken = (token: string): void => {
  setStorage(tokenFiledName, token)
}
