import REQ from '@/utils/axios'
// 登录
export const reqLogin = <T>(data: object) =>
  REQ<T>({
    url: '/auth/v1/login',
    data,
    showLoading: true,
    // formatResponseData: false,
  })

// 登出
export const reqLoginOut = <T>() =>
  REQ<T>({
    method: 'GET',
    url: '/logout',
    showLoading: true,
  })

// 验证码
export const reqLoginCode = <T>() =>
  REQ<T>({
    method: 'GET',
    url: '/auth/v1/captcha/code',
  })

// 获取用户信息及权限列表
export const reqUserPermission = <T>() =>
  REQ<T>({
    method: 'GET',
    url: '/sys/user/v1/getUserInfo',
    showLoading: true,
  })

// 获取可访问路由树
export const reqGetRouters = <T>(data: object) =>
  REQ<T>({
    url: '/sys/menu/tree',
    data,
    showLoading: true,
  })

export const reqUserChangePwd = <T>(data: object) =>
  REQ<T>({
    url: '/sys/user/v1/uptPwd',
    data,
    showLoading: true,
  })
// 获取字典数据
export const dictList = <T>(data: any) => {
  return REQ<T>({
    method: 'GET',
    url: '/sys/dict/v1/list',
    data,
  })
}