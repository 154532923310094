import { defineStore } from 'pinia'
import type { Config } from './interface/index'
const logo = new URL('../../assets/logo.png', import.meta.url).href
export const useConfig = defineStore('config', {
  state: (): Config => ({
    isKeepLoginStatus: true, // 是否长期缓存登录状态
    systemConfig: {
      systemName: 'OA系统',
      systemLogo: logo,
    },
  }),
})
