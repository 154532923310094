/* 路由守卫 */
import { useUser } from '@/stores/user'
import { isLogin, saveToken } from '@/utils/login'
import type { RouteLocationNormalized } from 'vue-router'

// 获取权限
async function getPermissions() {
  const user = useUser()
  const { permissionStatus } = user
  const queue = []
  permissionStatus || queue.push(user.asyncGetUserInfo())

  return Promise.all(queue)
}
/**
 * 路由前置守卫
 * @param to
 * @returns
 */
export const routerBeforeEach = async (to: RouteLocationNormalized) => {
  if (to.query.token) {
    saveToken(to.query.token)
  }
  if (isLogin()) {
    // 判断是否获取了菜单
    // if (!user.permissionStatus) {
    //   await getPermissions()
    //   // 重定向
    //   return { path: to.path, replace: true }
    // }
    if (to.path === '/login') {
      return { path: '/login' }
    }
  } else {
    if (to.path !== '/login') {
      return { path: `/login?redirect=${to.path}` }
    }
  }
}

/**
 * 后置路由守卫
 * @param {*} to
 */
export const routerAfterEach = (to: RouteLocationNormalized) => {
  document.title = `${to.query.title || to.meta?.title || ''}`
}
